<template>
    <w-simple-form
        :key="keyComponent"
        :disabled="isFormDisabled"
        :item="item"
        :fields="studentFormFields"
        :errors="responseErrors"
        @input:level_uuid="value => filterByLevel = value"
        deletable
        @save="onSave"
        @update="onUpdate"
        @delete="onDelete"
    ></w-simple-form>
</template>

<script>
import Inputmask from 'inputmask'
import { emailValidator, regexValidator, required } from '@/utils/validation'
import { deleteStudent, storeStudent, updateStudent } from '@apps/school/api/students'
import hasLevelsPropertyMixin from '@apps/school/mixins/hasLevelsPropertyMixin'
import { GROUP, LEVEL } from '@/widgets/components/WIcon/icons'
import ConfirmationSets from '@/utils/ConfirmationSets'
import { memberFullName } from '@/components/Member/utils'

export default {
    name: 'StudentForm',
    mixins: [ hasLevelsPropertyMixin ],
    props: {
        item: {
            type: Object,
            default: function () {
                return {}
            }
        },
        group: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            keyComponent: 0,
            isFormDisabled: false,
            responseErrors: {},
            filterByLevel: null,
            fieldBaseBindings: {
                outlined: true,
                dense: true,
                required: true
            },
            formData: {}
        }
    },
    computed: {
        isFormItem() {
            return Object.keys(this.item).length > 0
        },
        groupsItems() {
            if (!this.filterByLevel) {
                return []
            }

            return this.levels.find(o => o.uuid === this.filterByLevel).groups
        },
        studentFormFields() {
            let fields = []

            if (!this.isFormItem && !this.group) {
                fields.push({
                    name: 'level_uuid',
                    type: 'v-select',
                    props: {
                        label: this.$trans('Level'),
                        ...this.fieldBaseBindings,
                        rules: [
                            required
                        ],
                        items: this.levels,
                        itemText: 'title',
                        itemValue: 'uuid',
                        prependInnerIcon: LEVEL
                    }
                })

                fields.push({
                    name: 'group_uuid',
                    type: 'v-select',
                    props: {
                        disabled: this.groupsItems.length === 0,
                        label: this.$trans('Group'),
                        ...this.fieldBaseBindings,
                        rules: [
                            required
                        ],
                        items: this.groupsItems,
                        itemText: 'title',
                        itemValue: 'uuid',
                        prependInnerIcon: GROUP
                    }
                })
            }

            fields = fields.concat([
                {
                    name: 'last_name',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Last name'),
                        ...this.fieldBaseBindings,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    name: 'first_name',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('First name'),
                        ...this.fieldBaseBindings,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    name: 'middle_name',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Middle name'),
                        ...this.fieldBaseBindings,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    name: 'gender',
                    type: 'w-gender-input',
                    props: {
                        ...this.fieldBaseBindings,
                        required: true,
                        locale: this.$store.getters.locale
                    }
                },
                {
                    name: 'birthday',
                    type: 'w-birthday-input',
                    props: {
                        ...this.fieldBaseBindings,
                        locale: this.$store.getters.locale,
                        firstDayOfWeek: this.$store.getters.firstDayOfWeek,
                        required: true
                    }
                },
                {
                    name: 'email',
                    type: 'v-text-field',
                    props: {
                        label: 'E-mail',
                        ...this.fieldBaseBindings,
                        rules: [
                            required,
                            emailValidator
                        ]
                    }
                },
                {
                    name: 'phone',
                    type: 'v-text-field',
                    on: {
                        focus: this.initPhoneMask
                    },
                    props: {
                        id: 'student-phone-input',
                        label: this.$trans('Phone'),
                        ...this.fieldBaseBindings,
                        rules: [
                            required,
                            v => regexValidator(v, '^\\+38 \\(0([0-9]{2})\\) ([0-9]{3})-([0-9]{2})-([0-9]{2})$')
                        ]
                    }
                }
            ])

            return fields
        }
    },
    watch: {
        item() {
            this.keyComponent++
        }
    },
    methods: {
        initPhoneMask() {
            const selector = document.getElementById('student-phone-input')
            const im = new Inputmask('+38 (099) 999-99-99')

            if (selector) {
                im.mask(selector)
            }
        },
        async onSave(data) {
            this.responseErrors = {}
            this.isFormDisabled = true

            if(this.group) {
                data.group_uuid = this.group
            }

            try {
                const response = await storeStudent(data)
                this.isFormDisabled = false
                this.$emit('save', response.data)
                this.$emit('submit', response.data)
            } catch (e) {
                this.isFormDisabled = false

                if (e.response.data.errors) {
                    this.responseErrors = e.response.data.errors
                }
            }
        },
        async onUpdate(data, item) {
            this.isFormDisabled = true
            try {
                const response = await updateStudent(item.uuid, data)
                this.isFormDisabled = false
                this.$emit('updated', response.data)
                this.$emit('submit', response.data)
            } catch (error) {
                this.responseErrors = error.response.data.errors || {}
                this.isFormDisabled = false
            }
        },
        async onDelete() {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${this.$trans('Delete')} ${memberFullName(this.item)}`
            }))

            if (!isConfirm) {
                return
            }

            try {
                await deleteStudent(this.item.uuid)
                this.$emit('submit')
                this.$emit('deleted')
            } catch (error) {}
        }
    }
}
</script>

<style lang=scss>

</style>
