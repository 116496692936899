import request from '@/plugins/request'

const prefix = 'students'

export const getStudentsCollection = payload => {
    const options = {
        method: 'GET',
        url: `${prefix}`,
        params: payload
    }

    return request(options)
}

export const getStudent = uuid => {
    const options = {
        method: 'GET',
        url: `${prefix}/${uuid}`,
    }

    return request(options)
}

/**
 *
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const storeStudent = data => {
    const options = {
        method: 'POST',
        url: `${prefix}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @param {Object} data
 * @returns {AxiosPromise}
 */
export const updateStudent = (uuid, data) => {
    const options = {
        method: 'PATCH',
        url: `${prefix}/${uuid}`,
        data: data
    }

    return request(options)
}

/**
 *
 * @param {String} uuid
 * @returns {AxiosPromise}
 */
export const deleteStudent = uuid => {
    const options = {
        method: 'DELETE',
        url: `${prefix}/${uuid}`
    }

    return request(options)
}
